<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 512 512"
    viewBox="0 0 512 512"
    id="booking"
  >
    <path
      fill="#ffffff"
      d="M126,71c0,18.146,0,411.378,0,430h340V71H126z"
      class="colorffffff svgShape"
    ></path>
    <path
      fill="#c4c4c4"
      d="M376,221c16.56,0,30,13.44,30,30c0,16.57-13.43,30-30,30h-90.02c-0.19-4.85-2.14-9.64-5.84-13.35
			c-7.81-7.81-20.47-7.81-28.28,0L238.51,281H216c-16.56,0-30-13.44-30-30c0-16.57,13.43-30,30-30H376z"
      class="color89ccff svgShape"
    ></path>
    <circle
      cx="291"
      cy="71"
      r="20"
      fill="#ffffff"
      class="colorffffff svgShape"
    ></circle>
    <path
      fill="#c4c4c4"
      d="M291,11c33.14,0,60,26.86,60,60s-60,90-60,90s-60-56.86-60-90S257.86,11,291,11z M311,71
			c0-11.05-8.95-20-20-20s-20,8.95-20,20s8.95,20,20,20S311,82.05,311,71z"
      class="color89ccff svgShape"
    ></path>
    <path
      fill="#ffffff"
      d="M138.72,437.36l42.61-42.61c7.99-7.8,20.89-7.57,28.59,0.7c7.4,7.94,6.84,20.43-0.83,28.11
			l-45.69,45.68c-13.33,13.33-21.81,25.39-37.4,29.97c-3.03,0.9-6.34,1.51-10,1.79H46.79l-0.71-0.71H46V303.72h0.08L68,281.8
			c7.99-7.99,21.02-7.81,28.79,0.51c7.4,7.94,6.83,20.44-0.84,28.11L86.08,321l24.35-25.06c4.27-4.27,9.99-6.2,15.57-5.81
			c4.63,0.33,9.17,2.27,12.72,5.81c7.81,7.81,7.81,20.47,0,28.28l14.14-14.14c7.81-7.81,20.47-7.81,28.28,0
			c7.82,7.81,7.82,20.47,0,28.29L238.51,281l13.35-13.35c7.81-7.81,20.47-7.81,28.28,0c3.7,3.71,5.65,8.5,5.84,13.35
			c0.22,5.38-1.73,10.83-5.84,14.94l-98.81,98.81c-0.07,0.06-0.13,0.12-0.19,0.18L138.72,437.36z"
      class="colorffffff svgShape"
    ></path>
    <path
      fill="#757575"
      d="M466,61H360.274C355.406,27.124,326.202,1,291,1s-64.406,26.124-69.274,60H126
				c-5.522,0-10,4.478-10,10v210.321c-2.159,0.64-4.279,1.527-6.317,2.69c-1.319-3.061-3.177-5.949-5.581-8.528
				c-11.62-12.44-31.139-12.783-43.169-0.755l-21.22,21.22C37.449,297.782,36,300.58,36,303.72v196.57
				c0,6.007,5.549,10.71,10.794,10.71c47.778-0.007,371.472-0.001,419.206,0c5.522,0,10-4.478,10-10V71
				C476,65.478,471.522,61,466,61z M291,21c27.57,0,50,22.43,50,50c0,18.894-28.31,53.963-50,75.98c-21.69-22.02-50-57.088-50-75.98
				C241,43.43,263.43,21,291,21z M115.596,491c-30.995-0.292-48.983-0.468-59.596-0.392V307.946l19.075-19.075
				c3.915-3.917,10.438-3.989,14.403,0.257c3.624,3.889,3.357,10.269-0.596,14.221c-0.187,0.187-1.025,1.093-10.111,10.83
				c-4.314,4.618-3.264,12.05,2.067,15.335c3.857,2.396,9.087,1.879,12.413-1.542l24.296-25.004
				c3.903-3.857,10.216-3.844,14.1,0.043c3.91,3.908,3.911,10.226,0.006,14.135c-29.736,29.838-28.527,28.277-29.572,29.86
				c-2.557,3.872-2.143,9.149,1.281,12.574c3.91,3.908,10.241,3.901,14.143,0c33.592-33.592,20.745-20.745,42.426-42.427
				c3.91-3.912,10.232-3.911,14.142,0c3.912,3.91,3.911,10.232,0,14.142l-42.426,42.427c-3.905,3.905-3.905,10.237,0,14.143
				c3.906,3.904,10.235,3.905,14.143,0l113.137-113.138c3.913-3.911,10.231-3.91,14.142,0.001c3.899,3.898,3.899,10.243,0,14.142
				c-1.268,1.268-137.651,137.646-141.426,141.421c-3.905,3.905-3.905,10.237,0,14.143c3.897,3.896,10.231,3.914,14.147,0
				l42.492-42.493c4.062-3.989,10.505-3.772,14.326,0.33c3.625,3.889,3.359,10.27-0.585,14.22l-45.69,45.681
				C140.848,477.653,133.584,489.468,115.596,491z M216,271c-11.046,0-20-8.955-20-20c0-11.028,8.972-20,20-20h160
				c11.046,0,20,8.955,20,20c0,11.028-8.972,20-20,20h-82.023c-1.459-3.797-3.709-7.358-6.766-10.415
				c-11.729-11.731-30.701-11.727-42.427-0.001L234.368,271H216z M456,491H156.333c15.354-15.358-26.191,25.885,59.835-60.374
				c11.664-11.679,12.134-30.124,1.072-41.992c-2.655-2.85-5.725-5.057-9.02-6.634l78.99-78.989
				c3.481-3.482,5.918-7.616,7.327-12.011H376c22.056,0,40-17.944,40-40c0-22.102-17.902-40-40-40H216c-22.056,0-40,17.944-40,40
				c0,21.568,17.05,39.124,38.408,39.96l-20.331,20.331c-7.56-15.804-26.921-21.743-41.972-13.111
				c-3.245-7.533-9.168-12.958-16.106-15.807V81h86.254c8.347,35.657,55.975,81.675,61.867,87.258c3.857,3.656,9.901,3.656,13.758,0
				c5.892-5.583,53.52-51.601,61.867-87.258H456V491z"
      class="color2169ac svgShape"
    ></path>
    <path
      fill="#757575"
      d="M321 71c0-16.542-13.458-30-30-30s-30 13.458-30 30 13.458 30 30 30S321 87.542 321 71zM281 71c0-5.514 4.486-10 10-10s10 4.486 10 10-4.486 10-10 10S281 76.514 281 71zM386 331h-80c-5.522 0-10 4.478-10 10s4.478 10 10 10h80c5.522 0 10-4.478 10-10S391.522 331 386 331zM386 381h-80c-5.522 0-10 4.478-10 10s4.478 10 10 10h80c5.522 0 10-4.478 10-10S391.522 381 386 381zM386 431h-80c-5.522 0-10 4.478-10 10s4.478 10 10 10h80c5.522 0 10-4.478 10-10S391.522 431 386 431z"
      class="color2169ac svgShape"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "bookingOrderIcon",
};
</script>

<style lang="scss" scoped></style>

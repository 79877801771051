<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="instock">
    <g data-name="48 In Stock" fill="#000000" class="color000000 svgShape">
      <path
        d="M374.794 324.857h-237.59a52.374 52.374 0 0 1-52.31-52.32v-86.11a6 6 0 0 1 12 0v86.11a40.36 40.36 0 0 0 40.31 40.32h237.59a40.36 40.36 0 0 0 40.31-40.32V74.247a40.356 40.356 0 0 0-40.31-40.31h-237.59a40.356 40.356 0 0 0-40.31 40.31v65.38a6 6 0 0 1-12 0v-65.38a52.37 52.37 0 0 1 52.31-52.31h237.59a52.37 52.37 0 0 1 52.31 52.31v198.29a52.374 52.374 0 0 1-52.31 52.32Z"
        fill="#000000"
        class="color000000 svgShape"></path>
      <path
        d="M90.894 173.527a6 6 0 0 1-6-6v-9a6 6 0 0 1 12 0v9a6 6 0 0 1-6 6zm122.496-51.43a32.857 32.857 0 0 1-14.09-3.2 32.465 32.465 0 0 1-18.723-29.587V27.936a6 6 0 0 1 6-6h138.846a6 6 0 0 1 6 6v61.366a32.74 32.74 0 0 1-53.48 25.335l-10.142-8.302a18.677 18.677 0 0 0-23.59-.001l-10.157 8.313a32.546 32.546 0 0 1-20.664 7.45zm-20.813-88.16V89.31a20.74 20.74 0 0 0 33.877 16.05l10.158-8.312a30.712 30.712 0 0 1 38.79.002l10.142 8.302a20.74 20.74 0 0 0 33.879-16.05V33.937zM232.1 282.538a42.215 42.215 0 0 1-29.943-12.38l-43.45-43.45a32.802 32.802 0 0 1 46.39-46.39l22.843 22.842a5.968 5.968 0 0 0 8.442 0l70.536-70.535a32.795 32.795 0 0 1 46.379 0 32.794 32.794 0 0 1-.013 46.391l-91.258 91.157a42.217 42.217 0 0 1-29.926 12.365zm-50.197-99.817a20.793 20.793 0 0 0-14.71 35.502l43.45 43.449a30.383 30.383 0 0 0 42.903.012l91.257-91.157a20.794 20.794 0 0 0 .009-29.416 20.794 20.794 0 0 0-29.409 0l-70.536 70.535a17.969 17.969 0 0 1-25.412 0l-22.842-22.842a20.738 20.738 0 0 0-14.71-6.083zm273.102 307.345H56.994a52.049 52.049 0 0 1-51.99-51.99v-98.71a52.049 52.049 0 0 1 51.99-51.99h38.85a6 6 0 0 1 5.358 3.298 40.13 40.13 0 0 0 36.002 22.181h237.59a40.13 40.13 0 0 0 36.003-22.181 6 6 0 0 1 5.357-3.299h38.85a52.054 52.054 0 0 1 52 51.99v98.71a52.054 52.054 0 0 1-52 51.99zm-398.01-190.69a40.035 40.035 0 0 0-39.99 39.99v98.71a40.035 40.035 0 0 0 39.99 39.99h398.01a40.04 40.04 0 0 0 40-39.99v-98.71a40.04 40.04 0 0 0-40-39.99H419.7a52.035 52.035 0 0 1-44.906 25.48h-237.59a52.034 52.034 0 0 1-44.906-25.48z"
        fill="#000000"
        class="color000000 svgShape"></path>
      <path
        d="M47.337 342.044a6 6 0 0 0-6 6V429.4a6 6 0 0 0 12 0v-81.356a6 6 0 0 0-6-6zm74.14 0a6 6 0 0 0-6 6v61.754l-36.963-63.905a7.702 7.702 0 0 0-14.37 3.856V429.4a6 6 0 0 0 12 0v-63.637l38.146 65.95a7.068 7.068 0 0 0 13.186-3.54v-80.13a6 6 0 0 0-6-6zm254.13 12c7.335 0 14.444 4.033 19.503 11.066a6 6 0 1 0 9.741-7.008c-7.342-10.205-18-16.058-29.245-16.058-21.337 0-38.697 20.94-38.697 46.678s17.36 46.678 38.697 46.678c11.248 0 21.907-5.85 29.246-16.05a6 6 0 1 0-9.742-7.008c-5.055 7.028-12.164 11.058-19.504 11.058-14.72 0-26.697-15.556-26.697-34.678s11.977-34.678 26.697-34.678zm93.521 70.654-32.535-36.249 31.002-34.54a6 6 0 0 0-8.93-8.016l-33.487 37.309v-35.158a6 6 0 0 0-12 0V429.4a6 6 0 0 0 12 0v-28.233l3.353-3.735 31.667 35.281a6 6 0 0 0 8.93-8.015zm-180.517-82.654c-23.113 0-41.916 20.94-41.916 46.678s18.803 46.678 41.916 46.678 41.915-20.94 41.915-46.678-18.803-46.678-41.915-46.678zm0 81.356c-16.496 0-29.916-15.557-29.916-34.678s13.42-34.678 29.916-34.678 29.915 15.556 29.915 34.678-13.42 34.678-29.915 34.678zm-117.385-40.567-.03-.004-.016-.001-.066-.006a24.466 24.466 0 0 0-1.352-.124c-.325-.02-.644-.028-.963-.035a14.37 14.37 0 1 1 12.279-24.148 6 6 0 1 0 8.694-8.272 26.374 26.374 0 1 0-22.262 44.359c.044.005.09.007.135.012l.06.009h.013l.057.003c.405.046.812.088 1.222.114.324.02.644.024.964.032a14.374 14.374 0 1 1-14.445 21.21 6 6 0 0 0-10.492 5.825 26.287 26.287 0 0 0 21.412 13.54q.846.053 1.68.052a26.377 26.377 0 0 0 3.11-52.566z"
        fill="#000000"
        class="color000000 svgShape"></path>
      <path
        d="M248.19 354.044a6 6 0 0 0 0-12h-45.898a6 6 0 1 0 0 12h16.949V429.4a6 6 0 0 0 12 0v-75.356Z"
        fill="#000000"
        class="color000000 svgShape"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "iconInStock",
};
</script>

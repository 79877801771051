<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51" id="printer">
    <path
      fill="#727478"
      d="m39.731 39.136-6.972-4.052-.986-.583 6.971 4.052z"
    ></path>
    <path
      fill="#b0b8cf"
      d="m38.744 38.553-6.971-4.052 7.279-12.489 6.972 4.052z"
    ></path>
    <path
      fill="#fff"
      d="m33.141 34.14 6.273-10.762 5.242 3.047-6.274 10.762z"
    ></path>
    <path
      fill="#9ea9c9"
      d="m46.024 26.064.986.583-7.279 12.489-.987-.583z"
    ></path>
    <path
      fill="#c9cee2"
      d="m38.805 18.6-5.643-3.255c-6.147 3.873-12.22 7.86-18.396 11.687l-.001.919v.44l-.01 4.84v.43l-.02 8.68 12.4 7.16 15-8.72v-3.76l.01-4.85v-.44l.01-4.85.01-.43v-3.39l-3.37-1.95.01-2.51zM19.245 19.412c-.002.11-.04.184-.101.22l-2.32 1.348c.062-.036-.192-.084-.192-.192 0-.219-.058-.162-.248-.272-.096-.056.015-.411-.046-.375l2.319-1.348c.061-.036.148-.03.244.024a.76.76 0 0 1 .344.595z"
    ></path>
    <path
      fill="#9ea9c9"
      d="M16.582 20.165c-.19-.11-.345-.021-.346.197s.153.485.344.595c.19.11.345.02.345-.198a.757.757 0 0 0-.343-.594z"
    ></path>
    <path
      fill="#c9cee2"
      d="M27.818 14.43c-.002.109-.04.184-.1.22l-2.32 1.348c.062-.036-.017-.037-.017-.144 0-.22-.23-.195-.42-.304-.095-.057.012-.428-.05-.392l2.32-1.347c.06-.036.148-.031.244.023a.76.76 0 0 1 .343.595z"
    ></path>
    <path
      fill="#9ea9c9"
      d="M25.156 15.182c-.191-.11-.346-.02-.346.197 0 .218.153.485.344.596.19.11.344.02.345-.198a.757.757 0 0 0-.343-.595z"
    ></path>
    <path
      fill="#c9cee2"
      d="M23.806 27.319 38.8 18.605l-.006 2.507L23.8 29.825z"
    ></path>
    <path
      fill="#4c5c75"
      d="m14.777 22.105 14.993-8.713 9.03 5.213-14.994 8.714z"
    ></path>
    <path
      fill="#1d2943"
      d="m16.257 22.103 13.515-7.854 7.548 4.358-13.515 7.854z"
    ></path>
    <path
      fill="#c9cee2"
      d="m42.167 23.06-.007 3.391-.001.433-.01 4.85-.001.434-.01 4.85v.434l-.008 3.334-14.994 8.713.008-3.39.001-.433.009-4.851.001-.434.011-4.85v-.434l.008-3.334z"
    ></path>
    <path
      fill="#e3e7f0"
      d="m23.8 29.825 14.994-8.713 3.374 1.948-14.994 8.713z"
    ></path>
    <path
      fill="#b0b8cf"
      d="m27.174 31.773-.008 3.343v.432l-.01 4.84-.002.432-.01 4.839v.432l-.008 3.408-12.403-7.16.008-3.841.01-4.84v-.432l.011-4.838.001-.433.013-5.85 9.03 5.214-.006 2.506 3.374 1.948z"
    ></path>
    <path
      fill="#f45170"
      d="m36.671 24.914-1.034.601-1.008-.581 1.035-.602z"
    ></path>
    <path
      fill="#72d561"
      d="m35.209 25.764-1.035.601-1.007-.581 1.035-.602z"
    ></path>
    <path
      fill="#1d2943"
      d="m33.306 26.993-4.256 2.473-1.275-.736 4.256-2.473z"
    ></path>
    <path
      fill="#4c5c75"
      d="m14.777 21.146 14.993-8.714 5.176-10.933-14.993 8.714z"
    ></path>
    <path
      fill="#1d2943"
      d="m35.928 39.906.002.697-2.528 1.459-.002-.697zM35.913 34.637l.002.697-2.528 1.459-.002-.697z"
    ></path>
    <path
      fill="#9ea9c9"
      d="M42.138 37.019v.433l-14.994 8.657.001-.433 6.258-3.613v-.001l2.526-1.459z"
    ></path>
    <path
      fill="#1d2943"
      d="m35.898 29.368.002.697-2.527 1.459-.002-.697z"
    ></path>
    <path
      fill="#9ea9c9"
      d="m42.149 31.734-.001.434-14.994 8.657.001-.434 6.232-3.597 2.528-1.46zM42.16 26.451l-.001.433-14.993 8.657v-.434l6.206-3.583 2.528-1.459z"
    ></path>
    <g fill="#9ea9c9">
      <path
        d="m27.145 45.659-.001.432L14.74 38.93l.001-.432zM27.155 40.387l-.001.433-12.402-7.161v-.433zM27.166 35.116v.432l-12.404-7.16.001-.433z"
      ></path>
    </g>
    <g fill="#9ea9c9">
      <path
        d="m25.328 37.339-.003 1.196 1.04.601.003-1.196zM25.333 35.46l-.003 1.195 1.04.601.004-1.196z"
      ></path>
    </g>
    <g fill="#9ea9c9">
      <path
        d="m25.328 42.864-.003 1.196 1.04.6.003-1.195zM25.333 40.984l-.003 1.196 1.04.6.004-1.195z"
      ></path>
    </g>
    <path
      fill="#9ea9c9"
      d="m25.333 46.254-.003 1.195 1.04.601.004-1.196z"
    ></path>
    <path
      fill="#f8f9fe"
      d="m20.457 20.586 5.592 3.25 7.389-4.266-5.592-3.25z"
    ></path>
    <path
      fill="#78e75a"
      d="M29.938 20.878c.537-.31.54-.813.007-1.123l-.973.562.966.561zm0 0-.966-.561-.974.561c.534.31 1.403.31 1.94 0z"
    ></path>
    <path
      fill="#78e75a"
      d="m28.972 20.317-.967-.562c-.537.31-.54.813-.007 1.123l.974-.561z"
    ></path>
    <path
      fill="#f45170"
      d="M29.945 19.615c-.533-.31-1.402-.31-1.939 0l.966.561.973-.561zm-5.1 1.949 1.034.173a.34.34 0 0 1 .152-.097l-.24-.614c-.347.064-.768.233-.945.538z"
    ></path>
    <path
      fill="#78e75a"
      d="M24.803 21.655c-.198.528.45.906 1.066.995l.16-.628c-.09-.03-.188-.097-.186-.193l-1.04-.174z"
    ></path>
    <path
      fill="#f45170"
      d="m26.186 22.052-.158.616c.654.05 1.675-.219 1.656-.854l-1.076.042c-.029.146-.26.206-.422.196z"
    ></path>
    <path
      fill="#4580dc"
      d="m25.949 21.003.236.607c.145-.009.343.035.407.152l1.08-.042c-.126-.57-1.062-.792-1.723-.717z"
    ></path>
    <g fill="#e3e7f0">
      <path
        d="m27.547 16.934 4.829 2.807.159-.092-4.829-2.806zM27.095 17.195l4.83 2.807.16-.093-4.829-2.807zM26.645 17.455l4.83 2.807.159-.092-4.83-2.807z"
      ></path>
    </g>
    <g fill="#e3e7f0">
      <path
        d="m25.539 18.101 2.076 1.207.159-.092-2.076-1.207zM25.088 18.362l2.075 1.206.161-.092-2.076-1.207zM24.637 18.622l2.076 1.206.159-.092-2.075-1.206z"
      ></path>
    </g>
    <g fill="#e3e7f0">
      <path
        d="m23.531 19.268 2.076 1.206.16-.092-2.076-1.206zM23.08 19.528l2.076 1.207.16-.093-2.076-1.206zM22.63 19.788l2.076 1.207.159-.092-2.076-1.207z"
      ></path>
    </g>
    <path
      fill="#b0b8cf"
      d="m21.672 34.076-5.542-3.2-4.522-2.611-7.61 4.426v.001h-.001l-.004 5.995-.003 5.002.151.088 9.912 5.723 5.945-3.458 1.667-.969z"
    ></path>
    <path
      fill="#e9e7fc"
      d="m21.671 34.076-.008 10.997-7.61 4.427L3.99 43.689l.008-10.998 7.61-4.426z"
    ></path>
    <path
      fill="#e9e7fc"
      d="m21.672 34.076-.007 10.997-7.614 4.425.007-10.997z"
    ></path>
    <path
      fill="#f3f1fc"
      d="m14.058 38.501-.007 10.997L3.99 43.689l.007-10.997z"
    ></path>
    <path
      fill="#f8f9fe"
      d="m21.672 34.076-7.614 4.425-10.061-5.809 7.614-4.425z"
    ></path>
    <path
      fill="#78e75a"
      d="M8.763 34.474c-.731-.422-.736-1.107-.009-1.53l1.325.765-1.316.765zM8.763 34.474l1.316-.765 1.325.765c-.727.422-1.91.422-2.64 0z"
    ></path>
    <path
      fill="#78e75a"
      d="m10.079 33.71 1.316-.766c.732.423.736 1.108.01 1.53l-1.326-.765z"
    ></path>
    <path
      fill="#f45170"
      d="M8.753 32.753c.727-.422 1.909-.422 2.64 0l-1.315.765-1.325-.765zm6.943 2.654-1.406.236a.464.464 0 0 0-.207-.132l.326-.836c.473.087 1.046.317 1.287.732z"
    ></path>
    <path
      fill="#78e75a"
      d="M15.755 35.53c.269.72-.613 1.235-1.452 1.356l-.219-.854c.125-.043.257-.133.254-.263l1.417-.238z"
    ></path>
    <path
      fill="#f45170"
      d="m13.872 36.072.214.838c-.89.069-2.28-.298-2.253-1.162l1.464.057c.04.199.355.28.575.267z"
    ></path>
    <path
      fill="#4580dc"
      d="m14.194 34.644-.322.827c-.196-.013-.466.047-.553.206l-1.47-.057c.17-.776 1.445-1.079 2.345-.976z"
    ></path>
    <g fill="#c9cee2">
      <path
        d="m12.019 29.104-6.576 3.821-.216-.125 6.575-3.821zM12.634 29.459 6.058 33.28l-.218-.126 6.575-3.821zM13.247 29.813l-6.576 3.821-.217-.125 6.576-3.821z"
      ></path>
    </g>
    <g fill="#c9cee2">
      <path
        d="m14.752 30.693-2.826 1.642-.217-.125 2.827-1.643zM15.367 31.047l-2.826 1.643-.219-.126 2.827-1.643zM15.98 31.401l-2.826 1.643-.217-.125 2.826-1.643z"
      ></path>
    </g>
    <g fill="#c9cee2">
      <path
        d="m17.486 32.281-2.827 1.643-.216-.126 2.826-1.642zM18.101 32.636l-2.827 1.642-.218-.126 2.826-1.642zM18.714 32.99l-2.827 1.642-.217-.125 2.827-1.642z"
      ></path>
    </g>
    <path
      fill="#c9cee2"
      d="M8.773 38.353c-.57-.361-1.14-.72-1.7-1.097.605.298 1.202.611 1.8.924 2.442 1.297 3.629 1.99 5.2 2.882a164.453 164.453 0 0 1 5.167-2.882c.594-.313 1.187-.626 1.79-.924-.557.376-1.124.736-1.69 1.097-1.573.99-3.08 1.91-5.164 3.122l-.103.06-.102-.06c-2.092-1.225-2.954-1.72-5.198-3.122z"
    ></path>
    <path
      fill="#b0b8cf"
      d="M19.24 38.18c-1.63.87-3.215 1.75-5.165 2.88v.474l.1-.06a164.34 164.34 0 0 0 5.166-3.12c.565-.362 1.132-.722 1.688-1.098-.602.298-1.195.611-1.79.924z"
    ></path>
    <path
      fill="#c9cee2"
      d="M9.475 43.102c-.494-.313-.988-.625-1.475-.951.526.258 1.043.53 1.561.801 2.118 1.125 3.147 1.725 4.509 2.499 1.68-.974 3.054-1.737 4.48-2.499.515-.271 1.03-.543 1.551-.801-.482.326-.973.638-1.464.951a141.866 141.866 0 0 1-4.478 2.707l-.09.052-.087-.051c-1.814-1.063-2.562-1.492-4.507-2.708z"
    ></path>
    <path
      fill="#b0b8cf"
      d="M18.55 42.952a142.548 142.548 0 0 0-4.478 2.498v.41l.087-.05a142.489 142.489 0 0 0 4.478-2.708c.49-.313.982-.625 1.464-.951-.522.258-1.036.53-1.551.801z"
    ></path>
    <path
      fill="#c9cee2"
      d="M9.19 40.661c1.713.842 3.899 2.1 4.876 2.651 1.371-.795 3.468-1.97 4.846-2.65-1.26.85-3.346 2.107-4.774 2.938l-.072.042-.07-.041c-.91-.539-3.177-1.847-4.806-2.94z"
    ></path>
    <path
      fill="#b0b8cf"
      d="M14.068 43.311v.33l.07-.04c1.435-.836 3.52-2.092 4.774-2.94-.42.208-3.477 1.858-4.844 2.65z"
    ></path>
    <path
      fill="#c9cee2"
      d="M10.374 45.365c-.396-.252-.793-.502-1.184-.764.422.207.838.425 1.254.643 1.701.904 2.528 1.386 3.622 2.008 1.371-.795 3.468-1.97 4.846-2.651-1.26.851-3.346 2.108-4.774 2.938l-.072.042-.07-.041c-1.458-.854-2.058-1.198-3.622-2.175z"
    ></path>
    <path
      fill="#b0b8cf"
      d="M14.068 47.25v.33l.07-.04c1.435-.835 3.52-2.091 4.774-2.94-1.38.682-3.477 1.858-4.844 2.65z"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "printerIcon",
};
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    id="DeliveryOrder"
  >
    <path
      fill="#9b9b9b"
      d="M44.3 7.8H6c-1.7 0-3 1.3-3 3V60c0 1.7 1.3 3 3 3h38.3c1.7 0 3-1.3 3-3V10.8c0-1.6-1.3-3-3-3z"
      class="colorb4d562 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M44.3 64H6c-2.2 0-4-1.8-4-4V10.8c0-2.2 1.8-4 4-4h38.3c2.2 0 4 1.8 4 4V60c0 2.2-1.8 4-4 4zM6 8.8c-1.1 0-2 .9-2 2V60c0 1.1.9 2 2 2h38.3c1.1 0 2-.9 2-2V10.8c0-1.1-.9-2-2-2H6z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#ffffff"
      d="M43.4 10.8v48.3H17.6L7 48.4V10.8z"
      class="colorffffff svgShape"
    ></path>
    <path
      fill="#757575"
      d="M43.4 59.6H17.6c-.1 0-.3-.1-.4-.1L6.6 48.8c-.1-.1-.1-.2-.1-.4V10.8c0-.3.2-.5.5-.5h36.4c.3 0 .5.2.5.5v48.3c0 .2-.2.5-.5.5zm-25.6-1h25.1V11.3H7.5v36.9l10.3 10.4z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M17.6 59.1 7 48.4h10.6z"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M17.6 59.6c-.1 0-.3-.1-.4-.1L6.6 48.8c-.1-.1-.2-.4-.1-.5.1-.3.3-.4.5-.4h10.7c.3 0 .5.2.5.5v10.7c0 .2-.1.4-.3.5h-.3zM8.2 48.9l9 9v-9h-9z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#a4a4a4"
      d="M30.1 6c0-2.7-2.2-5-5-5-2.7 0-5 2.2-5 5h-5.6c-1.4 0-2.6.9-3.1 2.1l-1.3 3.5H40l-1.3-3.5c-.4-1.3-1.6-2.1-3-2.1h-5.6z"
      class="colorfdef4c svgShape"
    ></path>
    <path
      fill="#757575"
      d="M40 12.5H10.3c-.3 0-.6-.2-.8-.4-.2-.3-.2-.6-.1-.9l1.3-3.5c.6-1.7 2.2-2.8 4-2.8h4.7c.5-2.8 2.9-5 5.9-5 2.9 0 5.4 2.1 5.9 5h4.7c1.8 0 3.4 1.1 4 2.8l1.3 3.5c.1.3.1.6-.1.9s-.7.4-1.1.4zm-28.3-2h26.9l-.8-2.1c-.3-.9-1.1-1.4-2.1-1.4h-5.6c-.6 0-1-.4-1-1 0-2.2-1.8-4-4-4s-4 1.8-4 4c0 .6-.4 1-1 1h-5.6c-.9 0-1.8.6-2.1 1.5l-.7 2z"
      class="color5c3290 svgShape"
    ></path>
    <circle
      cx="25.2"
      cy="6"
      r="1.9"
      fill="#e4e4e4"
      class="colore5e3e5 svgShape"
    ></circle>
    <path
      fill="#757575"
      d="M25.2 8.3c-1.3 0-2.4-1.1-2.4-2.4 0-1.3 1.1-2.4 2.4-2.4 1.3 0 2.4 1.1 2.4 2.4 0 1.4-1.1 2.4-2.4 2.4zm0-3.7c-.8 0-1.4.6-1.4 1.4s.6 1.4 1.4 1.4c.8 0 1.4-.6 1.4-1.4s-.7-1.4-1.4-1.4z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M9.1 17.3H13v3.9H9.1z"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M13.1 21.8h-4c-.3 0-.5-.2-.5-.5v-3.9c0-.3.2-.5.5-.5H13c.3 0 .5.2.5.5v3.9c.1.3-.2.5-.4.5zm-3.5-1h2.9v-2.9H9.6v2.9z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M16.6 17.3H20" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M20 17.8h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H20c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M32.1 17.3H40" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 17.8h-7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M21.7 17.3h8.6" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M30.3 17.8h-8.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8.6c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M28.3 19.3h-3.4"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M28.3 19.8h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.4c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M30.1 19.3H40" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 19.8h-9.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M23.2 19.3h-6.6"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M23.2 19.8h-6.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6.6c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M36.6 21.3H40" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 21.8h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M34.8 21.3h-9.9"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M34.8 21.8h-9.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9.9c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M23.2 21.3h-6.6"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M23.2 21.8h-6.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6.6c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M9.1 32.3H13v3.9H9.1z"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M13.1 36.8h-4c-.3 0-.5-.2-.5-.5v-3.9c0-.3.2-.5.5-.5H13c.3 0 .5.2.5.5v3.9c.1.3-.2.5-.4.5zm-3.5-1h2.9v-2.9H9.6v2.9z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M40 32.3h-3.4" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 32.8h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M24.5 32.3h-7.9"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M24.5 32.8h-7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h7.9c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M34.9 32.3h-8.6"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M34.9 32.8h-8.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8.6c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M28.3 34.3h3.5" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M31.8 34.8h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.4c.3 0 .5.2.5.5s-.3.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M26.5 34.3h-9.9"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M26.5 34.8h-9.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9.9c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M33.4 34.3H40" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 34.8h-6.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M20 36.3h-3.4" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M20 36.8h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H20c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M21.8 36.3h10" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M31.8 36.8h-9.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9.9c.3 0 .5.2.5.5s-.3.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M33.4 36.3H40" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 36.8h-6.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M9.1 24.8H13v3.9H9.1z"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M13.1 29.3h-4c-.3 0-.5-.2-.5-.5v-3.9c0-.3.2-.5.5-.5H13c.3 0 .5.2.5.5v3.9c.1.3-.2.5-.4.5zm-3.5-1h2.9v-2.9H9.6v2.9z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M16.6 25.8H20" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M20 26.3h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H20c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M32.1 25.8H40" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 26.3h-7.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M21.7 25.8h8.6" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M30.3 26.3h-8.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8.6c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M28.3 27.8h-3.4"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M28.3 28.3h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.4c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M30.1 27.8H40" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 28.3h-9.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M23.2 27.8h-6.6"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M23.2 28.3h-6.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6.6c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M9.1 39.8H13v3.9H9.1z"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M13.1 44.3h-4c-.3 0-.5-.2-.5-.5v-3.9c0-.3.2-.5.5-.5H13c.3 0 .5.2.5.5v3.9c.1.3-.2.5-.4.5zm-3.5-1h2.9v-2.9H9.6v2.9z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M28.3 40.8h-3.4"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M28.3 41.3h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h3.4c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M30.1 40.8H40" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 41.3h-9.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M23.2 40.8h-6.6"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M23.2 41.3h-6.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6.6c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path fill="#e4e4e4" d="M36.6 42.8H40" class="colore5e3e5 svgShape"></path>
    <path
      fill="#757575"
      d="M40 43.3h-3.4c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H40c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M34.8 42.8h-9.9"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M34.8 43.3h-9.9c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9.9c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#e4e4e4"
      d="M23.2 42.8h-6.6"
      class="colore5e3e5 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M23.2 43.3h-6.6c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h6.6c.3 0 .5.2.5.5s-.2.5-.5.5z"
      class="color5c3290 svgShape"
    ></path>
    <circle
      cx="50"
      cy="52"
      r="11"
      fill="#e4e4e4"
      class="colore5e3e5 svgShape"
    ></circle>
    <path
      fill="#757575"
      d="M50 64c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12zm0-22c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10z"
      class="color5c3290 svgShape"
    ></path>
    <path
      fill="#757575"
      d="M48.3 56.5c-.3 0-.5-.1-.7-.3l-3.7-3.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l3 3 6.4-6.4c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L49 56.2c-.2.2-.5.3-.7.3zM11.9 20.4c-.1 0-.2 0-.2-.1L10.4 19c-.1-.1-.1-.4 0-.5s.4-.1.5 0l1.1 1.1 2.5-2.5c.1-.1.4-.1.5 0 .1.1.1.4 0 .5l-2.7 2.7c-.2.1-.3.1-.4.1zM11.9 27.9c-.1 0-.2 0-.2-.1l-1.3-1.3c-.1-.1-.1-.4 0-.5s.4-.1.5 0l1.1 1.1 2.5-2.5c.1-.1.4-.1.5 0 .1.1.1.4 0 .5l-2.7 2.7c-.2.1-.3.1-.4.1zM11.9 35.4c-.1 0-.2 0-.2-.1L10.4 34c-.1-.1-.1-.4 0-.5s.4-.1.5 0l1.1 1.1 2.5-2.5c.1-.1.4-.1.5 0 .1.1.1.4 0 .5l-2.7 2.7c-.2.1-.3.1-.4.1zM11.9 42.9c-.1 0-.2 0-.2-.1l-1.3-1.3c-.1-.1-.1-.4 0-.5s.4-.1.5 0l1.1 1.1 2.5-2.5c.1-.1.4-.1.5 0 .1.1.1.4 0 .5l-2.7 2.7c-.2.1-.3.1-.4.1z"
      class="color5c3290 svgShape"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "saleOrderDeliveryIcon",
};
</script>

<style lang="scss" scoped></style>

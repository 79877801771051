<template>
  <div>
    <v-divider></v-divider>
    <div class="text-xs-center pt-2 mx-5">
      <v-row>
        <v-col cols="12" sm="12" md="5" lg="5" class="d-flex justify-center">
          <v-pagination
            class="pagination"
            v-model="options.page"
            :length="totalPages"
            :total-visible="7"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
          ></v-pagination>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          md="3"
          lg="4"
          class="d-flex justify-center align-center"
        >
          <span class="mr-2"> แสดงรายการต่อหน้า </span>
          <v-select
            v-model="options.itemsPerPage"
            :items="listItemsPerPage"
            item-text="text"
            item-value="value"
            dense
            outlined
            hide-details
            class="pa-0 ma-0"
            style="max-width: 120px"
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="pl-0 d-flex justify-center align-center"
        >
          <span v-if="options.itemsPerPage !== -1">
            <!-- แสดงรายการ 1-5 จาก 10 รายการ-->
            แสดงรายการ
            {{ options.page * options.itemsPerPage - options.itemsPerPage + 1 }}
            -
            {{
              options.page * options.itemsPerPage > totalItems
                ? totalItems
                : options.page * options.itemsPerPage
            }}
            จาก {{ totalItems }} รายการ
          </span>
          <span v-else>แสดงทั้งหมด {{ totalItems }} รายการ</span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "TablePagination",
  props: {
    totalItems: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    listItemsPerPage: [
      { text: "1", value: 1 },
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "15", value: 15 },
      { text: "30", value: 30 },
      { text: "50", value: 50 },
      { text: "ทั้งหมด", value: -1 },
    ],
  }),
};
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="accessories">
    <path
      fill="#3f3a34"
      d="M32 1C14.906 1 1 14.907 1 32c0 2.983.438 5.923 1.271 8.754A15.916 15.916 0 0 0 1 47c0 8.822 7.178 16 16 16 2.215 0 4.325-.453 6.245-1.271A31.01 31.01 0 0 0 32 63c17.094 0 31-13.907 31-31S49.094 1 32 1zM3 47c0-7.72 6.28-14 14-14s14 6.28 14 14-6.28 14-14 14S3 54.72 3 47zm5.014-14h1.258c-.423.234-.836.484-1.235.754-.01-.257-.017-.511-.023-.754zM31 55.96c-.244-.01-.488-.012-.731-.029.262-.388.503-.791.731-1.202v1.231zM32 61c-2.087 0-4.151-.233-6.169-.67a16.154 16.154 0 0 0 2.956-2.547c1.063.13 2.137.217 3.213.217 9.309 0 17.964-5.026 22.589-13.117l-1.736-.992c-.273.477-.562.942-.864 1.396l-4.03-2.928-1.176 1.618 4.02 2.92a24.046 24.046 0 0 1-3.909 3.902l-2.917-4.016-1.617 1.176 2.919 4.018a24.177 24.177 0 0 1-4.923 2.501l-1.533-4.717-1.902.618 1.536 4.726a23.878 23.878 0 0 1-5.457.861V51h-.527c.332-1.281.527-2.617.527-4 0-8.822-7.178-16-16-16-1.383 0-2.719.195-4 .527V31H8.025c.078-1.883.375-3.709.864-5.457l4.73 1.537.619-1.902-4.734-1.539a23.941 23.941 0 0 1 2.516-4.919l4.02 2.921 1.176-1.618-4.015-2.917a24.201 24.201 0 0 1 3.905-3.905l2.917 4.016 1.617-1.176-2.92-4.02a23.89 23.89 0 0 1 4.919-2.516l1.539 4.734 1.902-.618-1.537-4.731A23.896 23.896 0 0 1 31 8.025V13h2V8.025c1.883.078 3.71.375 5.457.864l-1.538 4.731 1.902.618 1.539-4.734a23.941 23.941 0 0 1 4.919 2.516l-2.92 4.02 1.617 1.176 2.917-4.016a24.161 24.161 0 0 1 3.905 3.905l-4.015 2.917 1.176 1.618 4.02-2.921a23.89 23.89 0 0 1 2.516 4.919l-4.734 1.539.619 1.902 4.73-1.537a23.85 23.85 0 0 1 .864 5.457H51v2h4.974a23.864 23.864 0 0 1-.854 5.461l-4.74-1.541-.619 1.902 4.736 1.539c-.2.536-.412 1.067-.651 1.591l1.818.831A25.763 25.763 0 0 0 58 32C58 17.664 46.337 6 32 6S6 17.664 6 32c0 .837.039 2.246.113 3.308a16.135 16.135 0 0 0-2.443 2.861A29.114 29.114 0 0 1 3 32C3 16.009 16.01 3 32 3s29 13.009 29 29-13.01 29-29 29z"
    ></path>
    <path
      fill="#3f3a34"
      d="M34.019 28.567A3.947 3.947 0 0 0 32 28c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4c0-.74-.215-1.424-.567-2.019l9.274-9.274-1.414-1.414-9.274 9.274zM32 34c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm-15 1c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12zm6.648 19.449-1.725-2.374-1.617 1.176 1.727 2.376a9.911 9.911 0 0 1-4.032 1.322V54h-2v2.949a9.922 9.922 0 0 1-4.032-1.322l1.727-2.376-1.617-1.176-1.725 2.374a10.04 10.04 0 0 1-2.5-3.426l2.799-.91-.617-1.902-2.802.911C7.083 48.438 7 47.729 7 47s.083-1.438.232-2.123l2.802.911.617-1.902-2.799-.91a10.033 10.033 0 0 1 2.5-3.426l1.725 2.374 1.617-1.176-1.727-2.376a9.911 9.911 0 0 1 4.032-1.322V40h2v-2.949a9.922 9.922 0 0 1 4.032 1.322l-1.727 2.376 1.617 1.176 1.725-2.374a10.027 10.027 0 0 1 2.5 3.426l-2.799.91.617 1.902 2.802-.911c.151.684.234 1.393.234 2.122s-.083 1.438-.232 2.123l-2.802-.911-.617 1.902 2.799.91a10.046 10.046 0 0 1-2.5 3.425z"
    ></path>
    <path
      fill="#3f3a34"
      d="M17 45c-.08 0-.155.014-.232.023L14.8 42.4l-1.6 1.2 1.961 2.615A2.002 2.002 0 0 0 17 49c1.103 0 2-.897 2-2s-.897-2-2-2z"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "mileIcon",
};
</script>
<style></style>

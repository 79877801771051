<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 8.467 8.467"
    id="wiper"
  >
    <g
      fill-rule="evenodd"
      color="#ffff"
      font-family="sans-serif"
      font-weight="800"
    >
      <path
        style="
          line-height: normal;
          text-indent: 0;
          text-align: start;
          text-decoration-line: none;
          text-decoration-style: solid;
          text-decoration-color: #000;
          text-transform: none;
          white-space: normal;
          isolation: auto;
          mix-blend-mode: normal;
          solid-color: #000;
          solid-opacity: 1;
        "
        d="M4.233 290.782c-.938 0-1.877.135-2.687.403a.132.132 0 0 0-.081.076l-1.19 2.911a.132.132 0 0 0 .122.182H8.07a.132.132 0 0 0 .122-.182l-1.19-2.911a.132.132 0 0 0-.08-.076c-.811-.268-1.75-.403-2.689-.403zm0 .268c.894 0 1.784.127 2.55.373l1.09 2.666H.593l1.091-2.666c.766-.246 1.656-.373 2.55-.373zm-.529 3.568v.265h1.059v-.265z"
        overflow="visible"
        transform="translate(0 -288.533)"
      ></path>
      <path
        style="
          line-height: normal;
          text-indent: 0;
          text-align: start;
          text-decoration-line: none;
          text-decoration-style: solid;
          text-decoration-color: #000;
          text-transform: none;
          white-space: normal;
          isolation: auto;
          mix-blend-mode: normal;
          solid-color: #000;
          solid-opacity: 1;
        "
        d="M4.497 293.163a.132.132 0 0 0-.072.244l.286.19-.719 1.079a.132.132 0 1 0 .22.147l.793-1.188a.132.132 0 0 0-.037-.184l-.396-.264a.132.132 0 0 0-.075-.024z"
        overflow="visible"
        transform="translate(0 -288.533)"
      ></path>
      <path
        style="
          line-height: normal;
          text-indent: 0;
          text-align: start;
          text-decoration-line: none;
          text-decoration-style: solid;
          text-decoration-color: #000;
          text-transform: none;
          white-space: normal;
          isolation: auto;
          mix-blend-mode: normal;
          solid-color: #000;
          solid-opacity: 1;
        "
        d="M5.555 291.444a.132.132 0 0 0-.109.059l-1.453 2.113a.132.132 0 1 0 .218.15l1.453-2.114a.132.132 0 0 0-.11-.208z"
        overflow="visible"
        transform="translate(0 -288.533)"
      ></path>
    </g>
  </svg>
</template>
<script>
export default {
  name: "wiperIcon",
};
</script>
<style lang=""></style>

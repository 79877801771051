import Vue from "vue";

import saleOrderIcon from "@/assets/images/icon/saleOrderIcon.vue";
import deliveryOrderIcon from "@/assets/images/icon/deliveryOrderIcon.vue";
import quotationIcon from "@/assets/images/icon/quotationIcon.vue";
import bookingOrderIcon from "@/assets/images/icon/bookingOrderIcon.vue";
import saleOrderDeliveryIcon from "@/assets/images/icon/saleOrderDeliveryIcon.vue";
import deletePhotoIcon from "@/assets/images/icon/deletePhotoIcon.vue";
import iconStockItem from "@/assets/images/icon/iconStockItem.vue";
import iconSparesPart from "@/assets/images/icon/iconSparesPart.vue";
import iconSoldOut from "@/assets/images/icon/iconSoldOut.vue";
import iconInStock from "@/assets/images/icon/iconInStock.vue";
import iconCar from "@/assets/images/icon/iconCar.vue";
import breakIcon from "@/assets/images/icon/breakIcon.vue";
import wiperIcon from "@/assets/images/icon/wiperIcon.vue";
import bahtIcon from "@/assets/images/icon/bahtIcon.vue";
import mileIcon from "@/assets/images/icon/mileIcon.vue";
import reportCarIcon from "@/assets/images/icon/reportCarIcon.vue";
import printerIcon from "@/assets/images/icon/printerIcon.vue";
import documentIcon from "@/assets/images/icon/documentIcon.vue";
import multipleDocumentIcon from "@/assets/images/icon/multipleDocumentIcon.vue";

Vue.component("sale-order-icon", saleOrderIcon);
Vue.component("delivery-order-icon", deliveryOrderIcon);
Vue.component("quotation-icon", quotationIcon);
Vue.component("booking-order-icon", bookingOrderIcon);
Vue.component("sale-order-delivery-icon", saleOrderDeliveryIcon);
Vue.component("delete-photo-icon", deletePhotoIcon);
Vue.component("icon-stock-item", iconStockItem);
Vue.component("icon-spares-part", iconSparesPart);
Vue.component("icon-sold-out", iconSoldOut);
Vue.component("icon-in-stock", iconInStock);
Vue.component("icon-car", iconCar);
Vue.component("icon-break", breakIcon);
Vue.component("icon-wiper", wiperIcon);
Vue.component("icon-baht", bahtIcon);
Vue.component("icon-mile", mileIcon);
Vue.component("icon-report-car", reportCarIcon);
Vue.component("icon-printer", printerIcon);
Vue.component("icon-document", documentIcon);
Vue.component("icon-multiple-document", multipleDocumentIcon);

const values = {
  deletephotoicon: {
    component: "delete-photo-icon",
  },
  saleordericon: {
    component: "sale-order-icon",
  },
  deliveryordericon: {
    component: "delivery-order-icon",
  },
  quotationicon: {
    component: "quotation-icon",
  },
  bookingordericon: {
    component: "booking-order-icon",
  },
  saleorderdeliveryicon: {
    component: "sale-order-delivery-icon",
  },
  iconstockitem: {
    component: "icon-stock-item",
  },
  iconsparespart: {
    component: "icon-spares-part",
  },
  iconsoldout: {
    component: "icon-sold-out",
  },
  iconinstock: {
    component: "icon-in-stock",
  },
  iconCar: {
    component: "icon-car",
  },
  breakIcon: {
    component: "icon-break",
  },
  wiperIcon: {
    component: "icon-wiper",
  },
  bahtIcon: {
    component: "icon-baht",
  },
  mileIcon: {
    component: "icon-mile",
  },
  reportCarIcon: {
    component: "icon-report-car",
  },
  IconPrinter: {
    component: "icon-printer",
  },
  documentIcon: {
    component: "icon-document",
  },
  multipleDocumentIcon: {
    component: "icon-multiple-document",
  },
};

export default values;

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 50 64"
    viewBox="0 0 50 64"
    id="Document"
  >
    <path fill="#b5b5b5" d="M1 57h48v6H1z" class="colorafb6bb svgShape"></path>
    <path fill="#eaeaea" d="M49 14H36V1z" class="colore7eced svgShape"></path>
    <path
      fill="#ffffff"
      d="M49 14v43H1V1h35v13z"
      class="colorffffff svgShape"
    ></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="2"
      d="M49 57v6H1V1h35l13 13z"
      class="colorStroke000000 svgStroke"
    ></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="2"
      d="M36 1v13h13M1 57h48M5 7h12M5 11h8M19 7h2M7 22h22M7 28h36M7 34h36M7 40h36M7 46h36"
      class="colorStroke000000 svgStroke"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "documentIcon",
};
</script>

<style lang="scss" scoped></style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    id="productservices">
    <path
      d="M28.854 18.336a2.326 2.326 0 0 0-2.3-.07l-7.613 3.705a2.502 2.502 0 0 0-2.441-1.969h-4.086l-1.707-1.707a1 1 0 0 0-.707-.293H6V17a1 1 0 0 0-1-1H3a1 1 0 1 0 0 2h1v9H3a1 1 0 1 0 0 2h2a1 1 0 0 0 1-1v-.999h1.766l4.57 2.265c.987.49 2.027.73 3.052.73a6.557 6.557 0 0 0 4.012-1.378l9.581-6.338A2.347 2.347 0 0 0 30 20.35c0-.833-.429-1.585-1.146-2.013zm-.99 2.286L18.24 26.99c-1.496 1.149-3.325 1.323-5.016.485l-4.78-2.37A.989.989 0 0 0 8 25.001H6v-4.999h3.586l1.707 1.707a1 1 0 0 0 .707.293h4.5a.5.5 0 0 1 0 1H12a1 1 0 1 0 0 2h4.5c.074 0 .144-.016.217-.022a.987.987 0 0 0 .72-.052l10.03-4.882a.352.352 0 0 1 .363.008c.064.037.17.126.17.295a.34.34 0 0 1-.137.273z"
      fill="#757575"
      class="color000000 svgShape"></path>
    <path
      d="m7.649 15.937 8 3a1.01 1.01 0 0 0 .702 0l8-3A1 1 0 0 0 25 15V6a1 1 0 0 0-.648-.937l-8-3a1.01 1.01 0 0 0-.703 0l-8 3A1 1 0 0 0 7 6v9a1 1 0 0 0 .649.937zM9 7.443l6 2.25v6.864l-6-2.25V7.443zm8 9.114V9.693l6-2.25v6.864l-6 2.25zM16 4.068 21.152 6 16 7.932 10.848 6 16 4.068z"
      fill="#757575"
      class="color000000 svgShape"></path>
  </svg>
</template>

<script>
export default {
  name: "iconStockItem",
};
</script>

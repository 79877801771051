<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    id="HigherSparePartsCost">
    <path
      d="M272,416a56,56,0,1,0-56-56A56.0629,56.0629,0,0,0,272,416Zm0-96a40,40,0,1,1-40,40A40.04584,40.04584,0,0,1,272,320Z"
      fill="#757575"
      class="color000000 svgShape"></path>
    <path
      d="M383.33105,389.04688l18.53418-4.44434A8.00012,8.00012,0,0,0,408,376.82324V343.17676a7.99929,7.99929,0,0,0-6.13477-7.7793L383.332,330.9541a19.46691,19.46691,0,0,1-12.05957-29.10156l10.02344-16.35742a8.00053,8.00053,0,0,0-1.16406-9.83692l-23.791-23.78906a8.00062,8.00062,0,0,0-9.83594-1.16406l-16.35742,10.02344A19.467,19.467,0,0,1,301.04688,248.669l-4.44434-18.53418A8.00012,8.00012,0,0,0,288.82324,224H255.17676a7.99929,7.99929,0,0,0-7.7793,6.13477L242.9541,248.668a19.46691,19.46691,0,0,1-29.10156,12.05957L197.49512,250.7041l-8.35938,13.64258,16.35645,10.02344a35.46721,35.46721,0,0,0,53.02051-21.97071L261.48535,240h21.0293l2.97363,12.39941a35.46644,35.46644,0,0,0,53.01856,21.97168l10.98046-6.72851,14.8711,14.87012-6.72852,10.97949a35.46721,35.46721,0,0,0,21.97071,53.02051L392,349.48535v21.0293l-12.39941,2.97363a35.46644,35.46644,0,0,0-21.97168,53.01856l6.72851,10.98046L349.4873,452.3584l-10.97949-6.72852a35.46721,35.46721,0,0,0-53.02051,21.97071L282.51465,480h-21.0293l-2.97363-12.39941a35.46644,35.46644,0,0,0-53.01856-21.97168l-16.35742,10.02343,8.35938,13.64258,16.35742-10.02344a19.467,19.467,0,0,1,29.10058,12.05957l4.44434,18.53418A7.99929,7.99929,0,0,0,255.17676,496h33.64648a7.99929,7.99929,0,0,0,7.7793-6.13477l4.44336-18.5332a19.46691,19.46691,0,0,1,29.10156-12.05957l16.35742,10.02344a8.00187,8.00187,0,0,0,9.83692-1.16406l23.78906-23.79a8.00069,8.00069,0,0,0,1.16406-9.83692l-10.02344-16.35742a19.467,19.467,0,0,1,12.05957-29.10058Z"
      fill="#757575"
      class="color000000 svgShape"></path>
    <path
      d="M488 240H456V200a8.00039 8.00039 0 0 0-8-8H416a8.00039 8.00039 0 0 0-8 8v40H384v16h96v32H392v16h88v32H424v16h56v32H424v16h56v32H400v16h8v40a8.00039 8.00039 0 0 0 8 8h32a8.00039 8.00039 0 0 0 8-8V448h32a8.00039 8.00039 0 0 0 8-8V248A8.00039 8.00039 0 0 0 488 240zm-48 0H424V208h16zm0 240H424V448h16zM138.28125 245.7207l-4.5625 15.33594C151.874 266.457 160 274.46191 160 280c0 4.96875-6.25977 10.83789-16.33691 15.31641C131.06445 300.916 114.13672 304 96 304c-39.06836 0-64-14.21484-64-24 0-5.53809 8.126-13.543 26.28125-18.94336l-4.5625-15.33594C29.74805 252.85156 16 265.3457 16 280V458.28613c0 11.38867 9.24609 21.47657 26.03613 28.40528C56.58105 492.69434 75.74512 496 96 496s39.41895-3.30566 53.96387-9.30859C166.75391 479.7627 176 469.6748 176 458.28613V280C176 265.3457 162.252 252.85156 138.28125 245.7207zM48 471.84229c-9.72339-4.0398-16-9.35206-16-13.55616V440.57666a76.17724 76.17724 0 0 0 16 7.85327zm40 7.9624a144.74489 144.74489 0 0 1-24-3.05542V452.8999a153.55149 153.55149 0 0 0 24 2.91626zm40-3.05542a144.74489 144.74489 0 0 1-24 3.05542V455.81616a153.55149 153.55149 0 0 0 24-2.91626zm32-18.46314c0 4.2041-6.27661 9.51636-16 13.55616V448.42993a76.17724 76.17724 0 0 0 16-7.85327zM160 416c0 4.96875-6.25977 10.83789-16.33691 15.31641C131.06445 436.916 114.13672 440 96 440c-39.06836 0-64-14.21484-64-24V304.57666C46.35645 314.0752 69.2417 320 96 320s49.64355-5.9248 64-15.42334z"
      fill="#757575"
      class="color000000 svgShape"></path>
    <rect
      width="16"
      height="88"
      x="48"
      y="328"
      fill="#757575"
      class="color000000 svgShape"></rect>
    <rect
      width="16"
      height="88"
      x="88"
      y="336"
      fill="#757575"
      class="color000000 svgShape"></rect>
    <rect
      width="16"
      height="88"
      x="128"
      y="328"
      fill="#757575"
      class="color000000 svgShape"></rect>
    <path
      d="M88 216a8 8 0 0 1 16 0v64h16V216a24 24 0 0 0-48 0v64H88zM112 176H228l21.59961 28.7998a8.00048 8.00048 0 0 0 12.80078 0L284 176H400a24.0275 24.0275 0 0 0 24-24V40a24.0275 24.0275 0 0 0-24-24H112A24.0275 24.0275 0 0 0 88 40V152A24.0275 24.0275 0 0 0 112 176zM104 40a8.00917 8.00917 0 0 1 8-8H400a8.00917 8.00917 0 0 1 8 8V152a8.00917 8.00917 0 0 1-8 8H280a8.0009 8.0009 0 0 0-6.40039 3.2002L256 186.667 238.40039 163.2002A8.0009 8.0009 0 0 0 232 160H112a8.00917 8.00917 0 0 1-8-8z"
      fill="#757575"
      class="color000000 svgShape"></path>
    <path
      d="M176 144h32a48 48 0 0 0 0-96H176a48 48 0 0 0 0 96zm62.9873-56H223.32056a47.84552 47.84552 0 0 0-11.36182-23.74634A32.07943 32.07943 0 0 1 238.9873 88zm-15.66674 16H238.9873a32.07943 32.07943 0 0 1-27.02856 23.74634A47.84552 47.84552 0 0 0 223.32056 104zM176 64a32 32 0 1 1-32 32A32.03667 32.03667 0 0 1 176 64zM320 99.31348l10.34277 10.34375a8.003 8.003 0 0 0 11.31446 0L376 75.31445V96h16V56a8.00039 8.00039 0 0 0-8-8H344V64h20.68579L336 92.68652 325.65723 82.34277a8.003 8.003 0 0 0-11.31446 0l-48 48 11.31446 11.31446z"
      fill="#757575"
      class="color000000 svgShape"></path>
  </svg>
</template>

<script>
export default {
  name: "iconSparesPart",
};
</script>

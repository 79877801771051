<template>
  <svg xmlns="http://www.w3.org/2000/svg" color="black" id="baht">
    <path
      d="M9.5 23h1a.5.5 0 0 0 .5-.5V21h3a4.965 4.965 0 0 0 1.826-9.596c.014-.015.032-.026.046-.041a5.022 5.022 0 0 0 1.276-3.85A5.132 5.132 0 0 0 12 3h-1V1.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V3H8a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h1v1.5a.5.5 0 0 0 .5.5ZM11 5h1a3.119 3.119 0 0 1 3.158 2.702A3.002 3.002 0 0 1 12.172 11H11Zm0 8h2.828a3.119 3.119 0 0 1 3.158 2.702A3.002 3.002 0 0 1 14 19h-3ZM7 6a1 1 0 0 1 1-1h1v6H7Zm1 13a1 1 0 0 1-1-1v-5h2v6Z"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "bathIcon",
};
</script>
<style lang="scss" scoped></style>

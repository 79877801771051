// ScrollToTop.vue
<template>
  <v-btn
    v-if="showScrollToTop"
    class="scroll-to-top"
    fab
    color="primary"
    fixed
    bottom
    right
    small
    @click="scrollToTop"
  >
    <v-icon>mdi-arrow-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      showScrollToTop: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showScrollToTop = window.pageYOffset > 200;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
.scroll-to-top {
  z-index: 1000;
  margin-bottom: 50px;
}
</style>

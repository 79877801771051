<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="quotation">
    <g data-name="13-Quotation" fill="#757575" class="color000000 svgShape">
      <path
        d="M305.29,411.88h5a6,6,0,1,0,0-12H112.48V12H349v88.55a6,6,0,0,0,6,6h84.31V340.26a6,6,0,0,0,12,0V100.53c0-.13-.06-.24-.07-.37a6.07,6.07,0,0,0-.28-1.47c-.07-.2-.11-.38-.19-.57a6,6,0,0,0-1.12-1.73L359.3,1.85A6,6,0,0,0,354.8,0c-.07,0-.14,0-.21,0H106.49a6,6,0,0,0-6,6V399.9H36.79a6,6,0,0,0-6,6c0,42.1,30,76.35,66.87,76.35H310.3a6,6,0,0,0,0-12H97.67c-28.55,0-52.07-25.69-54.65-58.38H305.29ZM361,21.28l70.27,73.26H361Z"
        fill="#757575"
        class="color000000 svgShape"
      ></path>
      <path
        d="M420.81 334.18V159.27a6 6 0 0 0-6-6H136.94a6 6 0 0 0-6 6V334.18a6 6 0 0 0 6 6H414.82A6 6 0 0 0 420.81 334.18zm-12-145.88H224.69v-23H408.83zm-223.41 0v-23h27.29v23zm27.29 12v127.9H185.42V200.29zm-69.78-35h30.51v23H142.93zm0 35h30.51v127.9H142.93zm81.76 127.9V200.29H408.83v127.9zM345.11 364a6 6 0 0 0-6-6H318.57a6 6 0 1 0 0 12h20.55A6 6 0 0 0 345.11 364zM294.56 364a5.37 5.37 0 1 0 5.36-5.36A5.37 5.37 0 0 0 294.56 364zM132.47 71.74h65.08a6 6 0 0 0 0-12H132.47a6 6 0 1 0 0 12zM132.47 93.2h87.19a6 6 0 1 0 0-12H132.47a6 6 0 1 0 0 12zM243 87.21a5.37 5.37 0 1 0-5.36 5.36A5.36 5.36 0 0 0 243 87.21zM407.38 401.26c6.39.25 11.41 5.28 11.18 11.22a6 6 0 0 0 5.75 6.22 5.91 5.91 0 0 0 6.22-5.75c.41-10.52-6.74-19.62-16.72-22.59l.16-4.25a6 6 0 0 0-5.74-6.22 5.89 5.89 0 0 0-6.23 5.75l-.16 4.24c-10.2 2.16-18 10.68-18.44 21.22a22.16 22.16 0 0 0 6.37 16.45 23.92 23.92 0 0 0 16.3 7.21 11.92 11.92 0 0 1 8.17 3.58 10.38 10.38 0 0 1 3 7.64c-.24 5.93-5.53 10.52-12 10.31s-11.41-5.29-11.17-11.23a6 6 0 0 0-5.75-6.22 5.87 5.87 0 0 0-6.22 5.76c-.42 10.52 6.73 19.61 16.72 22.58l-.16 3.87a6 6 0 0 0 5.75 6.21h.24a6 6 0 0 0 6-5.76l.15-3.81c10.2-2.17 18-10.72 18.45-21.26A22.2 22.2 0 0 0 422.84 430a23.87 23.87 0 0 0-16.3-7.2 12 12 0 0 1-8.18-3.59 10.33 10.33 0 0 1-3-7.64C395.6 405.63 400.94 401 407.38 401.26z"
        fill="#757575"
        class="color000000 svgShape"
      ></path>
      <path
        d="M476.57,495.91a6,6,0,0,0-5.61-4.4,36.82,36.82,0,0,1-18.18-5.46,78.35,78.35,0,1,0-31.32,15.46,48.87,48.87,0,0,0,40.42,6.59l11-3a6,6,0,0,0,4.19-7.36Zm-37.85-5.53a49.93,49.93,0,0,0,10.65,7.5,36.28,36.28,0,0,1-23.08-7.64,6,6,0,0,0-3.62-1.22,5.76,5.76,0,0,0-1.59.22A65.47,65.47,0,1,1,439.49,481a6,6,0,0,0-.77,9.42Z"
        fill="#757575"
        class="color000000 svgShape"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "quotationIcon",
};
</script>

<style lang="scss" scoped></style>

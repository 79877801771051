<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" id="break">
    <g fill="#ffff">
      <path
        d="M297.3 108.6c0-5.6-1.6-11.1-4.9-15.8-5.1-7.3-13.4-11.7-22.3-11.8h-1.6C165.1 81 81 165.1 81 268.5v1.6c.1 8.9 4.5 17.2 11.8 22.3 4.7 3.2 10.2 4.9 15.7 4.9 6.2 34.8 23.7 66.7 50 90.9 30 27.6 69.1 42.8 109.9 42.8 89.6 0 162.5-72.9 162.5-162.5.1-79.7-56.6-146.3-133.6-159.9zM106 268.5c0-17.2 2.7-33.8 7.7-49.5l7 2.5c.8.3 1.7.5 2.6.5 3.1 0 5.9-1.9 7-4.9 1.4-3.9-.6-8.2-4.5-9.6l-6.9-2.5c5.5-12.8 12.6-24.8 21-35.7l5.3 4.5c1.4 1.2 3.1 1.8 4.8 1.8 2.1 0 4.3-.9 5.7-2.7 2.7-3.2 2.2-7.9-.9-10.6l-5.3-4.4c9.4-10.1 20.1-19 31.7-26.4l3.4 5.8c1.4 2.4 3.9 3.8 6.5 3.8 1.3 0 2.6-.3 3.7-1 3.6-2.1 4.8-6.7 2.7-10.2l-3.3-5.8c12.1-6.2 25.1-11 38.8-14l1.2 6.5c.6 3.6 3.8 6.2 7.4 6.2.4 0 .9 0 1.3-.1 4.1-.7 6.8-4.6 6.1-8.7l-1.1-6.5c6.8-.9 13.6-1.4 20.6-1.4h1.4c1 0 1.6.6 2 1 .3.5.7 1.3.3 2.3l-12.5 34.4c-61.9 4.3-111.5 53.9-115.8 115.8l-34.4 12.5c-1 .4-1.8 0-2.3-.3-.5-.3-1-.9-1-2l-.2-1.3zM268.5 406c-68.5 0-125.3-49.1-135.8-115.7l24.3-8.8c6.8-2.5 11.3-8.7 11.5-16 1.6-52.8 44.1-95.3 96.9-96.9 7.2-.2 13.5-4.7 16-11.5l8.8-24.3C356.8 143.2 406 200.2 406 268.5c0 75.8-61.7 137.5-137.5 137.5z"
      ></path>
      <path
        d="M351 268.5c0-45.5-37-82.5-82.5-82.5S186 223 186 268.5s37 82.5 82.5 82.5 82.5-37 82.5-82.5zM268.5 336c-37.2 0-67.5-30.3-67.5-67.5s30.3-67.5 67.5-67.5 67.5 30.3 67.5 67.5-30.3 67.5-67.5 67.5zm120.2-67.1c-4.1-.3-7.7 2.9-8 7-2.6 40.3-26.9 76.3-63.3 93.9-3.7 1.8-5.3 6.3-3.5 10 1.3 2.7 4 4.2 6.8 4.2 1.1 0 2.2-.2 3.3-.8 41.3-20 68.8-60.8 71.7-106.4.3-4-2.8-7.6-7-7.9zm-9.2-18.7c.6 3.7 3.8 6.3 7.4 6.3.4 0 .8 0 1.2-.1 4.1-.7 6.9-4.5 6.2-8.6-.8-5-1.9-10-3.4-14.8-1.2-4-5.3-6.3-9.3-5.1-4 1.2-6.3 5.3-5.1 9.3 1.3 4.2 2.3 8.6 3 13z"
      ></path>
      <path
        d="M268.5 211c-31.7 0-57.5 25.8-57.5 57.5s25.8 57.5 57.5 57.5 57.5-25.8 57.5-57.5-25.8-57.5-57.5-57.5zm0 100c-23.4 0-42.5-19.1-42.5-42.5s19.1-42.5 42.5-42.5 42.5 19.1 42.5 42.5-19.1 42.5-42.5 42.5z"
      ></path>
      <circle cx="268.5" cy="242" r="7.5"></circle>
      <circle cx="268.5" cy="292" r="7.5"></circle>
      <circle
        cx="246.8"
        cy="279.5"
        r="7.5"
        transform="rotate(-30 246.836 279.552)"
      ></circle>
      <circle
        cx="290.2"
        cy="254.5"
        r="7.5"
        transform="rotate(-30 290.138 254.554)"
      ></circle>
      <circle
        cx="290.2"
        cy="279.5"
        r="7.5"
        transform="rotate(-60 290.151 279.551)"
      ></circle>
      <circle
        cx="246.8"
        cy="254.5"
        r="7.5"
        transform="rotate(-60 246.85 254.55)"
      ></circle>
    </g>
  </svg>
</template>
<script>
export default {
  name: "breakIcon",
};
</script>
<style lang=""></style>

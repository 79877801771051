<template>
  <v-app
    id="flexy"
    :class="`${
      !$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'
    }`"
  >
    <router-view />
    <ScrollToTop />
  </v-app>
</template>

<script>
import ScrollToTop from "@/components/scroll-to-top/ScrollToTop.vue";
// import { Decode } from "@/services";
export default {
  name: "App",

  components: {
    ScrollToTop,
  },
  // beforeCreate() {
  //   let data = JSON.parse(Decode.decode(localStorage.getItem("iworkUser")));
  //   this.$OneSignal.setExternalUserId(data.id);
  // },
};
</script>

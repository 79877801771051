import { Decode } from "@/services";
import { defineAbility } from "@casl/ability";

// Function to get the decoded user from local storage
function getDecodedUser() {
  try {
    const abilityRules = localStorage.getItem("abilityRules");
    return abilityRules ? JSON.parse(Decode.decode(abilityRules)) : null;
  } catch (error) {
    console.error("Error decoding user:", error);
    return null;
  }
}

// Function to define user abilities
function defineUserAbility(abilityRules) {

  return defineAbility((can) => {

    if (abilityRules) {
      if (abilityRules && abilityRules.length > 0) {
        abilityRules.forEach((rule) => {
          can(rule.action, rule.subject);
        });
      }
    } else {
      can("read", "dashboard");
    }
  });
}

// Use the functions to get the decoded user and define abilities
const decodedUser = getDecodedUser();
const ability = defineUserAbility(decodedUser);


export default ability;

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 48 48"
    viewBox="0 0 48 48"
    id="Order"
  >
    <path
      d="M40.2,47.5H7.8c-1,0-1.8-0.8-1.8-1.8V5.1c0-1,0.8-1.8,1.8-1.8h32.4c1,0,1.8,0.8,1.8,1.8v40.5C42,46.7,41.2,47.5,40.2,47.5    z M9.6,43.8h28.7V7H9.6V43.8z"
      fill="#757575"
      class="color000000 svgShape"
    ></path>
    <path
      d="M15.6 9.4c-1 0-1.8-.8-1.8-1.8V2.3c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8v5.2C17.4 8.6 16.6 9.4 15.6 9.4zM24 9.4c-1 0-1.8-.8-1.8-1.8V2.3c0-1 .8-1.8 1.8-1.8s1.8.8 1.8 1.8v5.2C25.8 8.6 25 9.4 24 9.4zM32.4 9.4c-1 0-1.8-.8-1.8-1.8V2.3c0-1 .8-1.8 1.8-1.8 1 0 1.8.8 1.8 1.8v5.2C34.3 8.6 33.5 9.4 32.4 9.4z"
      fill="#757575"
      class="color000000 svgShape"
    ></path>
    <g fill="#757575" class="color000000 svgShape">
      <path
        d="M32.4 21.7H15.6c-1 0-1.8-.8-1.8-1.8 0-1 .8-1.8 1.8-1.8h16.9c1 0 1.8.8 1.8 1.8C34.3 20.9 33.5 21.7 32.4 21.7zM32.4 32.8H15.6c-1 0-1.8-.8-1.8-1.8 0-1 .8-1.8 1.8-1.8h16.9c1 0 1.8.8 1.8 1.8C34.3 31.9 33.5 32.8 32.4 32.8z"
        fill="#757575"
        class="color000000 svgShape"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "saleOrderIcon",
};
</script>

<style lang="scss" scoped></style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128 128"
    id="DeliveryList"
  >
    <path
      d="M109.64 26.81H100.7v9.27a.631.631 0 0 1-.91.57l-2.1-1.16a.625.625 0 0 0-.83 0l-2.11 1.17a.639.639 0 0 1-.91-.58V26.81H84.9a1.105 1.105 0 0 0-1.06 1.14V48.32a1.09 1.09 0 0 0 1.06 1.13h24.74a1.09 1.09 0 0 0 1.06-1.13V27.95A1.105 1.105 0 0 0 109.64 26.81zM50.953 89.531H18.987a1.5 1.5 0 1 0 0 3H50.953a1.5 1.5 0 0 0 0-3zM50.953 96.027H18.987a1.5 1.5 0 1 0 0 3H50.953a1.5 1.5 0 1 0 0-3zM50.953 70.708H18.987a1.5 1.5 0 1 0 0 3H50.953a1.5 1.5 0 0 0 0-3zM50.953 77.2H18.987a1.5 1.5 0 1 0 0 3H50.953a1.5 1.5 0 0 0 0-3zM50.953 51.885H18.987a1.5 1.5 0 0 0 0 3H50.953a1.5 1.5 0 0 0 0-3zM50.953 58.381H18.987a1.5 1.5 0 1 0 0 3H50.953a1.5 1.5 0 0 0 0-3z"
      fill="#757575"
      class="color000000 svgShape"
    ></path>
    <path
      d="M122.342,22.317A29.48,29.48,0,0,0,70.805,25.49h-1.62a3.2,3.2,0,0,0-3.055-2.271H55.272a2.165,2.165,0,0,1-2.052-1.548,11.136,11.136,0,0,0-21.236,0,2.165,2.165,0,0,1-2.051,1.548H20.28a3.2,3.2,0,0,0-3.055,2.271H8.83a6.421,6.421,0,0,0-6.42,6.42v87.3a6.423,6.423,0,0,0,6.42,6.43H76.37a6.429,6.429,0,0,0,6.42-6.43V63.691A29.549,29.549,0,0,0,94.037,67.4a28.3,28.3,0,0,0,3.368.2,29.813,29.813,0,0,0,28.185-20.69A29.514,29.514,0,0,0,122.342,22.317ZM76.69,116.02a2.136,2.136,0,0,1-2.14,2.14H10.65a2.136,2.136,0,0,1-2.14-2.14V33.11a1.071,1.071,0,0,1,1.07-1.07h7.489v4.849a1.07,1.07,0,0,0,1.07,1.071H67.97a29.418,29.418,0,0,0,4.909,16.453.8.8,0,0,1,.1.737l-3.012,7.828a2.639,2.639,0,0,0,3.474,3.385l3.245-1.347Zm46.035-69.993A26.494,26.494,0,0,1,83.541,60.661a3.815,3.815,0,0,0-3.459-.3l-7.008,2.907,2.709-7.041a3.818,3.818,0,0,0-.405-3.475,26.483,26.483,0,1,1,47.347-6.726Z"
      fill="#757575"
      class="color000000 svgShape"
    ></path>
    <path
      d="M64.146 59.1a3.831 3.831 0 0 1-2.719-1.125l-1.159-1.16A1.5 1.5 0 0 1 62.39 54.7l1.158 1.159a.867.867 0 0 0 1.2 0l3.923-3.922a1.458 1.458 0 0 1 .623-.355 3.175 3.175 0 0 0-2.874-1.845h-7.3a3.18 3.18 0 0 0-3.176 3.176v7.3a3.18 3.18 0 0 0 3.176 3.176h7.3a3.18 3.18 0 0 0 3.176-3.176V55.25l-2.727 2.727A3.831 3.831 0 0 1 64.146 59.1zM64.146 77.924A3.831 3.831 0 0 1 61.427 76.8l-1.159-1.158a1.5 1.5 0 0 1 2.121-2.121l1.159 1.158a.869.869 0 0 0 1.2 0l3.923-3.922a1.446 1.446 0 0 1 .623-.354 3.175 3.175 0 0 0-2.874-1.845h-7.3a3.179 3.179 0 0 0-3.176 3.175v7.3A3.18 3.18 0 0 0 59.12 82.2h7.3a3.18 3.18 0 0 0 3.176-3.176V74.073L66.865 76.8A3.831 3.831 0 0 1 64.146 77.924zM64.146 96.747a3.831 3.831 0 0 1-2.719-1.125l-1.159-1.159a1.5 1.5 0 0 1 2.122-2.121L63.548 93.5a.869.869 0 0 0 1.2 0l3.923-3.923a1.459 1.459 0 0 1 .622-.354 3.173 3.173 0 0 0-2.873-1.844h-7.3a3.18 3.18 0 0 0-3.176 3.176v7.3a3.179 3.179 0 0 0 3.176 3.175h7.3a3.179 3.179 0 0 0 3.176-3.175V92.9l-2.727 2.727A3.831 3.831 0 0 1 64.146 96.747z"
      fill="#757575"
      class="color000000 svgShape"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "deliveryOrderIcon",
};
</script>

<style lang="scss" scoped></style>

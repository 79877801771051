import TablePagination from "@/components/table/TablePagination.vue";
import { abilitiesPlugin } from "@casl/vue";
import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
import axios from "axios";
import OneSignalVue from "onesignal-vue";
import "sweetalert2/dist/sweetalert2.min.css";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";
import VueMask from "v-mask";
import Vue from "vue";
import VueAxios from "vue-axios";
import excel from "vue-excel-export";
import VueFeather from "vue-feather";
import InstantSearch from "vue-instantsearch";
import JsonExcel from "vue-json-excel";
import JsonViewer from "vue-json-viewer";
import VueQRCodeComponent from "vue-qr-generator";
import VueSkycons from "vue-skycons";
import VueSweetalert2 from "vue-sweetalert2";
import Vue2Filters from "vue2-filters";
import Vuebar from "vuebar";
import App from "./App.vue";
import "./plugins/base";
import vuetify from "./plugins/vuetify";
import router from "./router";
import ability from "./services/ability"; // Assuming your ability.js file
import store from "./store/store";

Vue.component("TablePagination", TablePagination);
Vue.component("qr-code", VueQRCodeComponent);
Vue.component("downloadExcel", JsonExcel);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(VueAxios, axios);
Vue.use(VueFeather);
Vue.use(VueSkycons, { color: "#1e88e5" });
Vue.use(InstantSearch);
Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(excel);
Vue.use(OneSignalVue);
Vue.use(Vue2Filters);
Vue.use(LottieVuePlayer);
Vue.use(JsonViewer);
Vue.use(abilitiesPlugin, ability, { useGlobalProperties: true });

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi",
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
  // beforeCreate() {
  //   this.$OneSignal
  //     .init({
  //       appId: "dc4b5340-acf2-49cc-91f1-b728b64b92ed",
  //       safari_web_id:
  //         "web.onesignal.auto.32358c52-eb5f-4c9c-a5b8-d10e6e7ce454",
  //       notifyButton: {
  //         enable: true,
  //         position: "bottom-left",
  //       },
  //       allowLocalhostAsSecureOrigin: true,
  //     })
  //     .then(() => {
  //       this.$OneSignal.showSlidedownPrompt();
  //     });
  // },
}).$mount("#app");

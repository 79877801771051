<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 51 63"
    viewBox="0 0 51 63"
    id="Document"
  >
    <path
      fill="#b5b5b5"
      d="M34 6H6v54H1V1h28z"
      class="colorafb6bb svgShape"
    ></path>
    <path
      fill="#eaeaea"
      d="M50 56v6H6v-6zM50 17H39V6z"
      class="colore7eced svgShape"
    ></path>
    <path
      fill="#ffffff"
      d="M50 17v39H6V6h33v11z"
      class="colorffffff svgShape"
    ></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="2"
      d="M39 6H6v56h44V17z"
      class="colorStroke000000 svgStroke"
    ></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="2"
      d="m34 6-5-5H1v59h5M39 6v11h11M6 56h44M11 12h11M11 16h7M24 12h2M11 25h20M11 31h34M11 37h34M11 43h34M11 49h34"
      class="colorStroke000000 svgStroke"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "multipleDocumentIcon",
};
</script>

<style lang="scss" scoped></style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    id="smart-car-report"
  >
    <g data-name="Layer 29">
      <path
        d="M33 50h1a1 1 0 000-2H33a1 1 0 000 2zM23 50h1a1 1 0 000-2H23a1 1 0 000 2zM24 54a1 1 0 001 1H40a1 1 0 000-2H25A1 1 0 0024 54z"
      ></path>
      <path
        d="M61 60H50.97a4.84 4.84 0 00.93-2H59a1 1 0 001-1V56a11.478 11.478 0 00-.22-2.2.035.035 0 00-.01-.02 10.428 10.428 0 00-.69-2.18A11 11 0 0049 45H43.41l-3.77-3.78A10.922 10.922 0 0031.86 38H23.65a4.984 4.984 0 00-1.58.26L8.79 42.68A7 7 0 004 49.32V55a1.015 1.015 0 00.78.98l8.3 1.84A4.985 4.985 0 0014.03 60H3a1 1 0 000 2H61a1 1 0 000-2zm-3.75-7.6c.02.06.04.11.06.17l-1.95-.47L54.32 51h2.14A8.7 8.7 0 0157.25 52.4zM31.86 40a8.942 8.942 0 016.36 2.64L40.59 45H31.87l-.72-5zm-8.1 0h5.37l.72 5H22.33zm-2.24.55l-1.17 4.07-4.73-2.11zM6 49.28l2.22.56L8.61 51H6zM18 60a3 3 0 113-3A3.009 3.009 0 0118 60zm3.97 0a4.84 4.84 0 00.93-2H42.1a4.84 4.84 0 00.93 2zM47 60a3 3 0 113-3A3.009 3.009 0 0147 60zm0-8a5.017 5.017 0 00-4.9 4H22.9a4.987 4.987 0 00-9.73-.21L6 54.2V53h4a.983.983 0 00.81-.42 1 1 0 00.14-.9l-1-3a1.01 1.01 0 00-.71-.65l-2.81-.7a4.967 4.967 0 012.99-2.75l3.39-1.13 7.78 3.46A.978.978 0 0021 47H49a8.967 8.967 0 015.62 2H52a1 1 0 00-.73 1.69l2.84 3a1 1 0 00.5.28l3.3.8A9.887 9.887 0 0158 56H51.9A5.017 5.017 0 0047 52zM61 2H56a.611.611 0 00-.13.03.477.477 0 00-.2.04.757.757 0 00-.18.09.388.388 0 00-.11.06l-5 4c-.02.01-.03.03-.05.04-.03.04-.06.07-.09.11s-.06.06-.09.1l-4.91 7.86L40.65 18H36a1.087 1.087 0 00-.39.08.988.988 0 00-.32.21l-5 5-4.78 4.79-4.71.94c-.03.01-.05.03-.08.04a.784.784 0 00-.21.09.355.355 0 00-.09.04L13.68 34H4V32H9a1 1 0 001-1V27a1 1 0 00-1-1H4V24H15a1 1 0 001-1V19a1 1 0 00-1-1H4V16H21a1 1 0 001-1V11a1 1 0 00-1-1H4V8H27a1 1 0 001-1V3a1 1 0 00-1-1H3A1 1 0 002 3V35a1 1 0 001 1H61a1 1 0 001-1V3A1 1 0 0061 2zM4 4H26V6H4zm0 8H20v2H4zm0 8H14v2H4zm0 8H8v2H4zm16 6H17.12L20 31.94zm5 0H22V30.82l3-.6zm5 0H27V29.41l3-3zm5 0H32V24.41l3-3zm5 0H37V20h3zm5 0H42V19.48l3-2.4zm5 0H47V15.29l3-4.8zm5 0H52V7.48l3-2.4zm5 0H57V4h3z"
      ></path>
    </g>
  </svg>
</template>
<script>
export default {
  name: "reportCarIcon",
};
</script>
<style lang="scss" scoped></style>
